import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const Login = () => {
    const [serverError, setServerError] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();

    const handleFormSubmit = (values, { setSubmitting }) => {
        setServerError('');
        axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/login`, values)
            .then(res => {
                if (res.data.message === "Success") {
                    localStorage.setItem('jwtToken', res.data.jwtToken);
                    localStorage.setItem('apiAccessToken', res.data.apiAccessToken);
                    localStorage.setItem('userid', res.data.userid);
    
                    console.log("JWT set in localStorage:", localStorage.getItem('jwtToken'));
                    console.log("API Token set in localStorage:", localStorage.getItem('apiAccessToken'));
                    console.log("User ID set in localStorage:", localStorage.getItem('userid')); // Log user ID
    
                    // Redirect to the dashboard after tokens are set
                    navigate('/dashboard');
                } else {
                    setServerError('Login failed, please try again.');
                }
            })
            .catch(err => {
                console.log(err);
                setServerError('An error occurred, please try again.');
            })
            .finally(() => setSubmitting(false));
    };
    

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh" flexDirection="column" bgcolor={theme.palette.background.default}>
            <Typography variant="h4" gutterBottom>Login</Typography>
            <Formik initialValues={{ email: '', password: '' }} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(1, 1fr)" sx={{ width: '300px' }}>
                            <TextField fullWidth variant="filled" label="Email" type="email" name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} error={!!touched.email && !!errors.email} helperText={touched.email && errors.email} />
                            <TextField fullWidth variant="filled" label="Password" type="password" name="password" onBlur={handleBlur} onChange={handleChange} value={values.password} error={!!touched.password && !!errors.password} helperText={touched.password && errors.password} />
                            {serverError && <Typography color="error" variant="body2" sx={{ textAlign: 'center' }}>{serverError}</Typography>}
                            <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>{isSubmitting ? 'Logging in...' : 'Login'}</Button>
                        </Box>
                    </form>
                )}
            </Formik>
            <Box mt={2}>
                <Typography>Don't have an account? <Link to="/signup">Sign up here</Link></Typography>
            </Box>
        </Box>
    );
};

export default Login;
