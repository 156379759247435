export const countryData = [
    { id: 1, name: "Sri Lanka", flag: "https://flagcdn.com/lk.svg" },
    { id: 2, name: "UAE", flag: "https://flagcdn.com/ae.svg" },
    { id: 3, name: "Malaysia", flag: "https://flagcdn.com/my.svg" },
    { id: 4, name: "Thailand", flag: "https://flagcdn.com/th.svg" },
    { id: 5, name: "India", flag: "https://flagcdn.com/in.svg" },
    { id: 6, name: "Indonesia", flag: "https://flagcdn.com/id.svg" },
    { id: 7, name: "Nepal", flag: "https://flagcdn.com/np.svg" },
    { id: 8, name: "Japan", flag: "https://flagcdn.com/jp.svg" },
    { id: 9, name: "Maldives", flag: "https://flagcdn.com/mv.svg" },
    { id: 10, name: "Greece", flag: "https://flagcdn.com/gr.svg" },
    { id: 11, name: "Austria", flag: "https://flagcdn.com/at.svg" },
    { id: 12, name: "China", flag: "https://flagcdn.com/cn.svg" },
    { id: 13, name: "Singapore", flag: "https://flagcdn.com/sg.svg" },
    { id: 14, name: "Vietnam", flag: "https://flagcdn.com/vn.svg" },
    { id: 15, name: "Turkey", flag: "https://flagcdn.com/tr.svg" },
    { id: 16, name: "Australia", flag: "https://flagcdn.com/au.svg" },
    { id: 17, name: "Qatar", flag: "https://flagcdn.com/qa.svg" },
    { id: 18, name: "Spain", flag: "https://flagcdn.com/es.svg" },
    { id: 19, name: "USA", flag: "https://flagcdn.com/us.svg" },
    { id: 20, name: "Pakistan", flag: "https://flagcdn.com/pk.svg" },
    { id: 21, name: "Canada", flag: "https://flagcdn.com/ca.svg" },
    { id: 22, name: "Brazil", flag: "https://flagcdn.com/br.svg" },
    { id: 23, name: "Germany", flag: "https://flagcdn.com/de.svg" },
    { id: 24, name: "France", flag: "https://flagcdn.com/fr.svg" },
    { id: 25, name: "South Korea", flag: "https://flagcdn.com/kr.svg" },
  ];
  