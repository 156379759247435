import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box,
    Typography,
    Grid,
    Chip,
    Button,
    Paper,
} from '@mui/material';
import { DataUsage, CalendarToday, Public, VerifiedUser, Speed, Security } from '@mui/icons-material';

const SingleProduct = () => {
    const { id } = useParams();
    const productId = id.split('-')[0]; // Extract numeric ID
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/product/${productId}`);
                setProduct(response.data);
                
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchProduct();
    }, [productId]);

    const handleCheckout = async () => {
        const userId = localStorage.getItem('userid');
        const token = localStorage.getItem('jwtToken');
        const items = [{ productId, quantity: 1 }];
        const totalAmount = product.price;

        console.log("User ID:", userId);
        console.log("JWT Token:", token);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_API_BASE_URL}/api/orders/checkout`,
                { userId, items, totalAmount },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("Order placed successfully:", response.data);
        } catch (error) {
            console.error("Error placing order:", error);
            console.log(totalAmount);
            console.log(items);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error fetching product: {error}</div>;

    return (
        <Box maxWidth="md" mx="auto" my={5} p={3} border="1px solid #ddd" borderRadius="12px" boxShadow={3}>
            {product ? (
                <>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" alignItems="center" mb={2}>
                                {product.country && (
                                    <img
                                        src={product.country.flag_url}
                                        alt={product.country.name}
                                        style={{ width: '50px', height: '30px', marginRight: '10px' }}
                                    />
                                )}
                                <Typography variant="h2"><strong>{product.package_name}</strong></Typography>
                            </Box>
                            <Typography variant="subtitle1" color="textSecondary">{product.description}</Typography>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1"><strong>Price:</strong></Typography>
                                <Chip label={`$${product.price}`} color="secondary" sx={{ ml: 2, fontSize: '1rem' }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src={product.package_image}
                                alt={product.package_name}
                                style={{ width: '100%', borderRadius: '8px' }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} mt={4}>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <DataUsage color="primary" sx={{ mr: 1 }} />
                                <Typography variant="body1">
                                    <strong>Data Amount:</strong> {product.data_amount === -1 ? 'Unlimited' : `${product.data_amount} GB`}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <CalendarToday color="primary" sx={{ mr: 1 }} />
                                <Typography variant="body1"><strong>Validity:</strong> {product.duration} days</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <Public color="primary" sx={{ mr: 1 }} />
                                <Typography variant="body1"><strong>Coverage:</strong> {Array.isArray(product.speed) ? product.speed.join(', ') : product.speed}</Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={4}>
                        {product.country && <Chip label={`Country: ${product.country.name}`} color="primary" sx={{ mr: 1 }} />}
                        {product.country?.region && <Chip label={`Region: ${product.country.region}`} color="secondary" sx={{ mr: 1 }} />}
                        {product.groups.map((group, index) => (
                            <Chip key={index} label={group} variant="outlined" color="primary" sx={{ mr: 1, mb: 1 }} />
                        ))}
                    </Box>

                    <Box mt={4} display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" size="large" onClick={handleCheckout}>
                            Proceed To Checkout
                        </Button>
                    </Box>

                    <Grid container spacing={3} mt={5}>
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={3} sx={{ padding: 2, textAlign: 'center', borderRadius: '8px' }}>
                                <VerifiedUser sx={{ fontSize: 40, color: 'green' }} />
                                <Typography variant="h5" mt={2}><strong>100% Original</strong></Typography>
                                <Typography variant="body2">Every product we offer is fully authentic, ensuring you receive only genuine items, backed by our commitment to quality.</Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Paper elevation={3} sx={{ padding: 2, textAlign: 'center', borderRadius: '8px' }}>
                                <Speed sx={{ fontSize: 40, color: 'blue' }} />
                                <Typography variant="h5" mt={2}><strong>Fast Service</strong></Typography>
                                <Typography variant="body2">Our streamlined processes guarantee fast and efficient service, from order placement to delivery.</Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Paper elevation={3} sx={{ padding: 2, textAlign: 'center', borderRadius: '8px' }}>
                                <Security sx={{ fontSize: 40, color: 'red' }} />
                                <Typography variant="h5" mt={2}><strong>Secure Service</strong></Typography>
                                <Typography variant="body2">Experience peace of mind with our secure service, featuring robust data protection to safeguard your information.</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Typography variant="h5" color="textSecondary">No product details available</Typography>
            )}
        </Box>
    );
};

export default SingleProduct;
