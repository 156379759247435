import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();

    const handlelogoClick = () => {
        navigate("/");
    };


return (
    <Box sx={{ boxShadow: "15px 5px 10px 8px rgba(0, 0, 0, 0.05)", padding:"40px 0px"}}>
        <Box display="flex" align-items= "self-start" justifyContent="space-between" p={2} sx={{ width: "80%", margin: "0 auto" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <img
                alt="site-logo"
                width="auto"
                height="50px"
                src={`../../assets/esim-brand-logo.png`}
                onClick={handlelogoClick}/>
            </Box>
            <Box>
                <Typography variant="h6"> Popular Countries </Typography>
                <Box>
                    <Typography> Link 1 </Typography>
                    <Typography> Link 2 </Typography>
                    <Typography> Link 3 </Typography>
                    <Typography> Link 4 </Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant="h6"> Partner with Us </Typography>
                <Box>
                    <Typography> Link 1 </Typography>
                    <Typography> Link 2 </Typography>
                    <Typography> Link 3 </Typography>
                    <Typography> Link 4 </Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant="h6">About Us </Typography>
                <Box>
                    <Typography> Link 1 </Typography>
                    <Typography> Link 2 </Typography>
                    <Typography> Link 3 </Typography>
                    <Typography> Link 4 </Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant="h6">More Info </Typography>
                <Box>
                    <Typography> Link 1 </Typography>
                    <Typography> Link 2 </Typography>
                    <Typography> Link 3 </Typography>
                    <Typography> Link 4 </Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant="h6">TravelData Careers </Typography>
                <Box>
                    <Typography> Link 1 </Typography>
                    <Typography> Link 2 </Typography>
                    <Typography> Link 3 </Typography>
                    <Typography> Link 4 </Typography>
                </Box>
            </Box>
        </Box>
    </Box>

    );
};

export default Footer;