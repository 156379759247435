export const mockDataOrders = [
    {
      id: 101,
      packageName: "Basic Subscription",
      date: "2024-01-15",
      price: "$19.99",
    },
    {
      id: 102,
      packageName: "Premium Subscription",
      date: "2024-02-10",
      price: "$49.99",
    },
    {
      id: 103,
      packageName: "Enterprise Package",
      date: "2024-03-05",
      price: "$99.99",
    },
    {
      id: 104,
      packageName: "Annual Subscription",
      date: "2024-04-20",
      price: "$199.99",
    },
    {
      id: 105,
      packageName: "Business Plan",
      date: "2024-05-11",
      price: "$149.99",
    },
    {
      id: 106,
      packageName: "Student Plan",
      date: "2024-06-25",
      price: "$9.99",
    },
    {
      id: 107,
      packageName: "Family Package",
      date: "2024-07-15",
      price: "$79.99",
    },
    {
      id: 108,
      packageName: "Unlimited Package",
      date: "2024-08-10",
      price: "$299.99",
    },
    {
      id: 109,
      packageName: "Corporate Subscription",
      date: "2024-09-01",
      price: "$499.99",
    },
    {
      id: 110,
      packageName: "Lifetime Membership",
      date: "2024-10-05",
      price: "$999.99",
    },
  ];
  