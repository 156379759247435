import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Footer from "./scenes/global/Footer";
import Dashboard from "./scenes/dashboard/index.jsx";
import Home from "./scenes/index.jsx"
import Signup from './scenes/signup/signup.jsx'
import Login from "./scenes/login/login.jsx";
import PrivateRoute from './components/PrivateRoute';
import SingleProduct from './components/singleProduct.jsx';
import Checkout from "./components/checkout.jsx";

function App() {
  const [theme, colorMode] = useMode();

  return( 
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Topbar />
        <main
          className="content"
          style={{
            justifyContent: 'center',
            height: `calc(100vh - 64px)`,
            overflowY: 'auto',
            padding: '20px 20px 0px 20px',
            width: '100%',
          }}>
            <div style={{ width: '100%',}}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              
              <Route path="/dashboard/*" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
              <Route path='/login' element={<Login/>}></Route>
              <Route path='/signup' element={<Signup/>}></Route>
              <Route path="/product/:id" element={<SingleProduct />} />
              <Route path="/checkout" element={<Checkout />} />
            </Routes>
          </div>
          <Footer />
          </main>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;



// import React from 'react'
// import Login from './Login'
// import {BrowserRouter, Routes, Route} from 'react-router-dom'
// import Signup from './Signup'
// import Home from './Home'

// function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path='/' element={<Login/>}></Route>
//         <Route path='/signup' element={<Signup/>}></Route>
//         <Route path='/home' element={<Home/>}></Route>
//       </Routes>
//     </BrowserRouter>
//   )
// }

// export default App