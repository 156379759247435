import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataOrders } from "../../data/mockDataOrders";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import Header from "../dashboard/Header";

const Order = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const columns = [
        { field: "id", headerName: "Order Number" }, 
        {field: "packageName", headerName: "Package", flex: 1, cellClassName:"name-column--cell",},
        {field: "date", headerName: "Date", type: "text", headerAlign: "left", align:"left",},
        {field: "price", headerName: "Price", flex:1,},
        // {field: "access", headerName: "Access Level", flex:1,
        //     renderCell: ({row: {access}}) => {
        //         return (
        //             <Box width="60%" m="0 auto" p="5px" display="flex" justifyContent="center" backgroundColor={
        //                 access === "admin"
        //                 ? colors.greenAccent[600]
        //                 : colors.greenAccent[700]
        //             }
        //             borderRadius="4px"
        //             >
        //                 {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
        //                 {access === "manager" && <SecurityOutlinedIcon />}
        //                 {access === "user" && <LockOpenOutlinedIcon />}
        //                 <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
        //                     {access}
        //                 </Typography>
        //             </Box>
        //         )
        //     }
        // },
    ];

    return(
        <Box m="20px">
            <Header title="Orders" subtitle="All the orders have been made" />
            <Box m="40px 0 0 0" height="75vh" sx={{
                "& .MuiDataGrid-root": {
                    border:"none",
                },
                "& .MuiDataGrid-cell":{
                    borderBottom: "none"
                },
                "& .name-column--cell":{
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeader":{
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none"
                },
                "& .MuiDataGrid-virtualScroller":{
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer":{
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
            }}>
            {mockDataOrders.length > 0 ? (
                <DataGrid 
                    rows={mockDataOrders} columns={columns} 
                />
            ) : (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="left"
                >
                    <img 
                        src={`../../assets/no_orders_yet_searching.png`} 
                        alt="No data available" 
                        style={{ width: '25%', marginRight: '20px' }}
                    />
                    <Box>
                        <Typography variant="h2" color={colors.grey[100]}>
                            No Orders Available
                        </Typography>
                        <Typography variant="subtitle1" color={colors.grey[300]}>
                            Currently, there are no orders made by you
                        </Typography>
                    </Box>
                </Box>
                )
            }
            </Box>
        </Box>
    )
}

export default Order;