// src/components/Checkout.js

import React, { useState } from 'react';
import axios from 'axios';

const Checkout = () => {
    const [items, setItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [message, setMessage] = useState('');

    const handleCheckout = async () => {
        try {
            const response = await axios.post('/api/checkout', {
                userId: 1,  // Replace with actual logged-in user ID
                items,
                totalAmount
            }, { withCredentials: true });

            setMessage(`Order successful! Order ID: ${response.data.orderId}`);
        } catch (error) {
            setMessage('Order failed. Please try again.');
            console.error(error);
        }
    };

    return (
        <div>
            <h1>Checkout</h1>
            <button onClick={handleCheckout}>Confirm Order</button>
            <p>{message}</p>
        </div>
    );
};

export default Checkout;
