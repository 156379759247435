import { Routes, Route } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import DashboardContent from "./DashboardContent";
import Order from "../orders";
import Profile from "../profile";
import { Box } from "@mui/material";

const Dashboard = () => {
  return (
    <Box sx={{ width: "60%", margin: "0 auto", minHeight: "70vh" }}>

    <div style={{ display: "flex" }}>
      <Sidebar />
      <div style={{ flexGrow: 1, display: "grid" }}>
        <Header />
        <Routes>
          <Route path="/" element={<DashboardContent />} />
          <Route path="/order" element={<Order />} />
          <Route path="/profile" element={<Profile />} />
          {/* Add more dashboard routes as needed */}
        </Routes>
      </div>
    </div>
    </Box>
  );
};

export default Dashboard;
