import { Box, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../dashboard/Header";
import axios from 'axios';
import { useEffect, useState } from 'react';

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const theme = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");

    useEffect(() => {
        // Retrieve user ID from local storage
        const userId = localStorage.getItem('userid'); // Ensure you store user ID during login

        axios.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/viewprofile/${userId}`) // Fetch user data
            .then(response => {
                setUserData(response.data); // Set user data from the response
            })
            .catch(error => {
                console.error("Error fetching user data", error);
            });
    }, []);

    const handleFormSubmit = (values) => {
        const userId = localStorage.getItem('userid'); // Get user ID again for the update request

        axios.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/updateprofile/${userId}`, values) // Assume you create this route
            .then(response => {
                console.log("Profile updated successfully:", response.data);
                // Optionally, show success message or redirect
            })
            .catch(error => {
                console.error("Error updating profile", error);
            });
    };

    const profileSchema = yup.object().shape({
        firstName: yup.string().required("Required"),
        lastName: yup.string().required("Required"),
        email: yup.string().email("Invalid Email").required("Required"),
        currentPassword: yup.string().required("Required"),
    });

    return (
        <Box m="20px">
            <Header title="Account Information" subtitle="Your account information can be seen here" />
            {userData && (
                <Formik
                    initialValues={{ 
                        firstName: userData.firstName, 
                        lastName: userData.lastName, 
                        email: userData.email, 
                        currentPassword: "" 
                    }}
                    validationSchema={profileSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}>
                                <TextField fullWidth variant="filled" label="First Name" onBlur={handleBlur} onChange={handleChange} value={values.firstName} name="firstName" error={!!touched.firstName && !!errors.firstName} helperText={touched.firstName && errors.firstName} sx={{ gridColumn: "span 2" }} />
                                <TextField fullWidth variant="filled" label="Last Name" onBlur={handleBlur} onChange={handleChange} value={values.lastName} name="lastName" error={!!touched.lastName && !!errors.lastName} helperText={touched.lastName && errors.lastName} sx={{ gridColumn: "span 2" }} />
                                <TextField fullWidth variant="filled" label="Email" onBlur={handleBlur} onChange={handleChange} value={values.email} name="email" error={!!touched.email && !!errors.email} helperText={touched.email && errors.email} sx={{ gridColumn: "span 4" }} />
                                <TextField fullWidth variant="filled" label="Current Password" onBlur={handleBlur} onChange={handleChange} value={values.currentPassword} name="currentPassword" error={!!touched.currentPassword && !!errors.currentPassword} helperText={touched.currentPassword && errors.currentPassword} sx={{ gridColumn: "span 4" }} />
                            </Box>
                            <button type="submit">Update Profile</button> {/* Add submit button */}
                        </form>
                    )}
                </Formik>
            )}
        </Box>
    );
};

export default Profile;
