import React from 'react'
import { Box, IconButton, Typography, useTheme } from "@mui/material"
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search"
import CountryGrid from "../components/countryGrid";
import DataPackageGrid from "../scenes/home/packagesGrid";


const Home = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    return (
        <Box 
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'auto',
            padding: '20px',
            width: '100%',
          }}
        > 
            <Box
              className="innerBoxContainer"
              maxWidth="1000px"
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              p={2}
              mb={4}
            >
                {/* Main Content Section */}
                <Box textAlign="center" mb={4}>
                    <Typography variant="h2" color={colors.grey[100]}>
                        Stay Connected with your loved ones on Your Journeys, All at Affordable Rates!
                    </Typography>
                    <Typography variant="h4" color={colors.grey[100]} mt={1} mb={3}>
                        Experience seamless connectivity with our affordable eSIM solutions.
                    </Typography>
                </Box>

                {/* Search Bar Section */}
                <Box
                    display="flex" 
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                    width="100%"
                    maxWidth="600px"
                    mb={4}
                >
                    <InputBase 
                        sx={{ ml: 2, flex: 1, color: colors.grey[100] }} 
                        placeholder="Search for a country" 
                        inputProps={{ 'aria-label': 'search' }}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon sx={{ color: colors.grey[100] }} />
                    </IconButton>
                </Box>
            </Box>

            {/* Country Grid Section */}
            <Box className="innerBoxContainer" >
                <Typography variant="h2" className="innerTitleContainer">
                    Popular Countries
                </Typography>
                <CountryGrid />
            </Box>

            {/* Country Package Section */}
            <Box className="innerBoxContainer" >
                <Typography variant="h2" className="innerTitleContainer">
                    Packages
                </Typography>
                <DataPackageGrid />
            </Box>

            <Box className="innerBoxContainer">
                <Typography variant="h2" className="innerTitleContainer">
                    How TravelData Works
                </Typography>
                <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={12}>
                    <Box textAlign="center">
                        <img
                        alt="steps_travel_data"
                        src={`../assets/step_1_open_travelData.png`}
                        style={{ width: '100%', height: 'auto' }}
                        />
                        <Typography variant="h5" className="subTitleContainer">Load e-sim TravelData site</Typography>
                    </Box>
                    
                    <Box textAlign="center">
                        <img
                        alt="site-steps_travel_data"
                        src={`../assets/step_2_choose_country_region.png`}
                        style={{ width: '100%', height: 'auto' }}
                        />
                        <Typography variant="h5" className="subTitleContainer">Choose your destination and package</Typography>
                    </Box>
                    
                    <Box textAlign="center">
                        <img
                        alt="steps_travel_data"
                        src={`../assets/step_3_scan_qr_code.png`}
                        style={{ width: '100%', height: 'auto' }}
                        />
                        <Typography variant="h5" className="subTitleContainer">Install your eSIM</Typography>
                    </Box>
                    
                    <Box textAlign="center">
                        <img
                        alt="steps_travel_data"
                        src={`../assets/step_4_use_esim_data.png`}
                        style={{ width: '100%', height: 'auto' }}
                        />
                        <Typography variant="h5" className="subTitleContainer">Activate your eSIM</Typography>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
}

export default Home;
